import React, { useState, useContext } from "react"

import Me from "../components/testcomp"
import Layout from "../components/customlayout"
import WelcomeHero from "../components/welcome"
import Features from "../components/features"


const Testpage = () => {
  const [btnState, setBtnState] = useState({ nav: false });
  const toggleNavDropdown = () => setBtnState({ nav: !btnState.nav });

  return (
    <Layout>
      <WelcomeHero />
      <Me />
      <a className="dropdown-item" href="/test">Action</a>
      <div className="dropdown">
        <button className={`btn btn-secondary dropdown-toggle ${btnState.nav ? 'show' : ''}`}
          type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true"
          data-target="#navbarSupportedContent"
          aria-expanded="false" onClick={toggleNavDropdown}>
          Dropdown button
          </button>
        <div className={`dropdown-menu ${btnState.nav ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="/test">Action</a>
          <a className="dropdown-item" href="/test">Another action</a>
          <a className="dropdown-item" href="/test">Something else here</a>
        </div>
      </div>
      <Features />
    </Layout>
  )
}
export default Testpage