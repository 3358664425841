import React from "react"
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

function WelcomeHero() {
  return (
    <div className="px-4 pt-5 text-center border-bottom bg-dark text-white">
      <h1 className="display-4 fw-bold">Want a musicbot?</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">Multifunctional bot that mainly focused on playing music, and moderation commands.</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        </div>
      </div>
      <div className="overflow-hidden" style={{maxHeight: '30vh'}}>
        <div className="container px-5">
          <StaticImage src="https://cdn.discordapp.com/avatars/297343587538960384/dc0f6c55dccce906595c652790bc00bf.png?size=512"
          className="img-fluid border rounded-3 shadow-lg mb-4"
          alt="Example image" width="700" height="500" loading="lazy" />
        </div>
      </div>
    </div>
  );
}

export default WelcomeHero
